import { loading } from "app/store";
import { BranchDataTokenStore } from '../stores/session.store';
import { httpRequest } from './http.service';
let _loading;
loading.subscribe((data) => {
    _loading = data;
});
import { PendingOrdersStore } from '../stores/orders.store';
import { Order } from '../../models/order.model';
const statusMapper = {
    pending: 'in_progress',
    in_progress: 'done',
    done: 'delivered',
};
// -------------------------------------------------- ANCHOR: SERVICES
export async function getPendingOrders() {
    const branchId = BranchDataTokenStore.getBranch()._id;
    const lastQueryTimestamp = localStorage.getItem('TREVLY_PENDING_ORDERS_QUERY_TIMESTAMP');
    const lastTotalDailyOrdersCount = PendingOrdersStore.getCurrentTotalOrders();
    const pendingOrderListCount = PendingOrdersStore.getCurrentPendingOrderListTotal();
    const UNFILTERED_QUERY_URL = `orders/${branchId}?statusFilter`;
    const FILTERED_QUERY_URL = `orders/${branchId}?statusFilter=pending&date=${lastQueryTimestamp}`;
    let response;
    let query = FILTERED_QUERY_URL;
    if (pendingOrderListCount === 0) {
        query = UNFILTERED_QUERY_URL;
    }
    if (pendingOrderListCount === lastTotalDailyOrdersCount && pendingOrderListCount !== 0) {
        query = FILTERED_QUERY_URL;
    }
    response = await httpRequest(query, {
        method: 'GET',
    });
    if (response.status !== 'success')
        return;
    PendingOrdersStore.updateTotalOrders(response.totalDailyOrders);
    if (response.data.length > 0) {
        const parsedPendingOrders = response.data.map((order) => new Order().parse(order));
        const filteredPendingOrders = parsedPendingOrders.filter(order => order.status != 'discarded' && order.status != 'delivered');
        PendingOrdersStore.append(...filteredPendingOrders);
        localStorage.setItem('TREVLY_PENDING_ORDERS_QUERY_TIMESTAMP', response.queryTimeStamp);
    }
    return response;
}
export async function getDeliveredOrders() {
    var _a;
    const branchId = BranchDataTokenStore.getBranch()._id;
    _loading.orders.getDeliveredOrders = true;
    loading.set(_loading);
    const res = await httpRequest(`orders/${branchId}?statusFilter=delivered`, {
        method: 'GET',
    })
        .finally(() => {
        _loading.orders.getDeliveredOrders = false;
        loading.set(_loading);
    });
    return (_a = res.data) !== null && _a !== void 0 ? _a : [];
}
async function updateOrderStatus(orderId, status) {
    const res = httpRequest(`orders/${orderId}/status`, {
        method: 'PUT',
        body: JSON.stringify({ status })
    });
    return res;
}
export async function setOrderAsDiscarded(orderId) {
    return updateOrderStatus(orderId, 'discarded').then((res) => {
        if (res.status !== 'success')
            return;
        PendingOrdersStore.discard(orderId);
        return res;
    });
}
export async function setOrderAsDelivered(orderId) {
    return updateOrderStatus(orderId, 'delivered').then((res) => {
        if (res.status !== 'success')
            return;
        PendingOrdersStore.deliver(orderId);
        return res;
    });
    ;
}
export async function setOrderToNextStatus(order) {
    const nextState = statusMapper[order.status];
    if (nextState === 'delivered') {
        return setOrderAsDelivered(order.id);
    }
    return updateOrderStatus(order.id, nextState).then((res) => {
        if (res.status !== 'success')
            return;
        PendingOrdersStore.updateState(order, nextState);
        return res;
    });
}
