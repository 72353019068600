<script lang="ts">
	import type { IFormInput } from '../core/models/form-input.model';
	import { createEventDispatcher } from 'svelte';
	import { validate } from '../core/helpers/validations';

	export let formInput: IFormInput;

	const dispatch = createEventDispatcher();
	let touched = false;
	let errorMessage = '';
	let timeout: any;

	/**
	 * this is beacuse of error:
	 * `'type' attribute cannot be dynamic if input uses two-way binding`
	 */
	const typeAction = (node) => {
		node.type = formInput.type;
	};

	const onInput = () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			errorMessage = validate(formInput);
			formInput.isValid = errorMessage.length === 0;
			dispatch('toggle-change', formInput);
		}, 300);
	}

	const change = (value: any) => {
		onInput();
	}

</script>

<div class="input-container">
	{#if formInput?.label}
		<label for={formInput.name}>
			{formInput.label}
		</label>
	{/if}

	<div>
		<label class="switch">
			<input 
				type="checkbox" 
				bind:checked={formInput.value}
				use:typeAction
				name={formInput.name}
				class:invalid={touched && errorMessage}
				on:change ={event => change(event)}
        	/>
			<span class="slider"></span>
		  </label>
		{#if touched && errorMessage}
			<p class="error-message">{errorMessage}</p>
		{/if}
	</div>
</div>

<style lang="scss">
	@import './../../assets/styles/vars/colors';
	@import './../../assets/styles/vars/fonts/typography';

	.input-container{
		display: flex;
		gap: 1%;
		justify-content: space-between;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 2.5rem;
		height: 1.3rem;

		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked {
				+ .slider {
					background-color: color(primary, base);
				}

				+ .slider:before {
					transform: translateX(1.1rem);
				}
			}
		}

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: color(input, accent-invert);
			transition: 0.4s;
			border-radius: 1rem;
			width: 100%;
			height: 100%;

			&::before {
				position: absolute;
				content: '';
				height: 1rem;
				width: 1rem;
				left: 0.2rem;
				bottom: 0.13rem;
				background-color: white;
				transition: 0.4s;
				border-radius: 50%;
			}
		}
	}
</style>
