import { Product } from "app/core/models/product.model";
import { httpRequest } from "./http.service";
import { formData, loading } from "app/store";
import { BranchDataTokenStore } from "../stores/session.store";
import { presentSimpleSnackbar } from "../internals/snackbar.service";
let _branch;
let _loading;
let _product;
BranchDataTokenStore.subscribe((data) => {
    _branch = data;
});
loading.subscribe((data) => {
    _loading = data;
});
export async function updateProduct(product) {
    _loading.products.updateProduct = true;
    loading.set(_loading);
    const response = await httpRequest(`${_branch._id}/products/status`, {
        method: 'PUT',
        body: JSON.stringify({
            productId: product.name,
            isEnabled: product.value
        }),
    }).finally(() => {
        _loading.products.updateProduct = false;
        loading.set(_loading);
    });
    if (response.status !== 'success')
        return;
    presentSimpleSnackbar('success', 'El producto se ha actualizado correctamente');
}
export async function getProducts(searchText) {
    _loading.products.getProducts = true;
    loading.set(_loading);
    const search = (searchText) ? `?search=${searchText}` : '';
    const response = await httpRequest(`${_branch._id}/products${search}`, {
        method: 'GET'
    }).finally(() => {
        _loading.products.getProducts = false;
        loading.set(_loading);
    });
    return (response.data || []).map((product) => new Product().parse(product));
}
export async function updateOptions(formInputs) {
    _loading.products.updateOptions = true;
    loading.set(_loading);
    _product.options.forEach(option => {
        formInputs.forEach(input => {
            if (option.name === input.name) {
                option.isEnabled = input.value;
            }
        });
    });
    console.log(_product.options);
    const response = await httpRequest(`${_branch._id}/products/options`, {
        method: 'PUT',
        body: JSON.stringify({
            productId: _product.id,
            options: _product.options
        }),
    }).finally(() => {
        setTimeout(function () {
            _loading.products.updateOptions = false;
            loading.set(_loading);
        }, 200);
    });
    if (response.status !== 'success')
        return;
    _product.totalOptions = response.data.totalOptions;
    _product.enabledOptions = response.data.enabledOptions;
    _product.disabledOptions = response.data.disabledOptions;
    formData.set(_product);
    presentSimpleSnackbar('success', 'El producto se ha actualizado correctamente');
    return response.status;
}
formData.subscribe((data) => {
    _product = data;
});
