<script lang="ts">
	import InputText from './input-text.svelte';
	import type { IFormInput } from 'app/core/models/form-input.model';
	import { createEventDispatcher } from 'svelte';
	import { parseDate } from 'app/core/pipes/date';
	import { parseCurrency } from 'app/core/pipes/currency';

	export let searchForm: IFormInput = null;
	export let module: string = '';
	export let totalInfo : any = {totalOrders: 0, totalCurrency: 0};

	const dispatch = createEventDispatcher();

	const searchChange = (e: CustomEvent) => {
		dispatch('search-change', e.detail.value);
	};
</script>

<div class="header__wrapper md-{module}">
	{#if searchForm}
		<div class="search__wrapper">
			<InputText
				formInput={searchForm}
				on:input-change={(event) => searchChange(event)}
				on:keyup-enter={(event) => searchChange(event)}
			/>
		</div>
		{:else if  module === 'deliveries'}
			 <p>{ parseDate(new Date()) }</p>
			 <p>Total: {totalInfo.totalOrders}  (${parseCurrency(totalInfo.totalCurrency)})</p>
	{/if}
</div>

<style lang="scss">
	@import './../../assets/styles/vars/fonts/typography';
	@import './../../assets/styles/vars/colors';

	.header__wrapper {
		width: 100%;
		padding-block: 1rem;
		display: flex;

		&.md-deliveries{
			flex-direction: column;
		}

		h1 {
			@extend %module-title;
			margin: 0 1rem 0 0;
		}

		p{
			margin: 0;
			font-weight: 600;
			color: color(neutral, text);
		}

		.search__wrapper {
			flex: 1;
			:global(.input-container > input) {
				margin-bottom: 0rem;
			}
		}
	}
</style>
