export function parseOptions(options, freeOptions) {
    let parsedOptions = '';
    options.forEach((option, index) => {
        if (index === 0) {
            parsedOptions += `${option.name}`;
            return;
        }
        if (index < freeOptions) {
            parsedOptions += `, ${option.name}`;
            return;
        }
        parsedOptions += `, ${option.name} (\$${option.additionalCost})`;
    });
    return parsedOptions;
}
;
