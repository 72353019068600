<script lang="ts">
	import HeaderSectionComponent from '../components/header-section-component.svelte';
	import OrderCardComponent from '../components/order-card-component.svelte';
	import SpinnerComponent from '../components/spinner-component.svelte';
	import { loading, windowWidthSize } from 'app/store';
	import { getDeliveredOrders } from 'app/core/services/api/orders.service';
	import { onMount } from 'svelte';
	import type { Order } from 'app/core/models/order.model';

	let module = 'deliveries';
	let _windowWidthSize: any = {};
	let _loading: any;

	let firstColumn: Order[] = [];
	let secondColumn: Order[] = [];
	let allCards: Order[] = [];
	let totalOrders: number = 0;
	let totalCurrency: number = 0;

	onMount(() => {
		getOrders();
	});

	const orderCards = () => {
		let increaseFor = 0;
		firstColumn = [];
		secondColumn = [];

		if (_windowWidthSize.large) {
			increaseFor = 2;
		} else {
			increaseFor = 1;
		}

		for (let index = 0; index < allCards.length; index += increaseFor) {
			firstColumn = [...firstColumn, allCards[index]];
			if (increaseFor >= 2 && allCards[index + 1]) {
				secondColumn = [...secondColumn, allCards[index + 1]];
			}
		}
	};

	windowWidthSize.subscribe((data) => {
		_windowWidthSize = data;
		orderCards();
	});

	const getOrders = async () => {
		await getDeliveredOrders().then((res) => {
			if (res) {
				totalOrders = res.length;
				res.forEach((order: Order) => totalCurrency += order.amount);
				allCards = [...res];
				orderCards();
			}
		});
	};

	loading.subscribe((data) => {
		_loading = data;
	});
</script>

<div class="page__wrapper">
	<div class="section__wrapper">
		<HeaderSectionComponent {module} totalInfo={{totalOrders, totalCurrency}}/>
		<div class="orders__wrapper">
			{#if _loading.orders.getDeliveredOrders}
				<SpinnerComponent />
			{:else if allCards?.length > 0}
				{#if firstColumn.length > 0}
					<div class="orders__column">
						{#each firstColumn as order}
							<OrderCardComponent {order} />
						{/each}
					</div>
				{/if}
				{#if _windowWidthSize.large}
					<div class="orders__column">
						{#each secondColumn as order}
							<OrderCardComponent {order} />
						{/each}
					</div>
				{/if}
			{:else}
				<p class="message-notFound">Aún no hay órdenes</p>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	.orders {
		&__wrapper {
			width: 100%;
			padding-bottom: 1rem;
			padding-inline: 0.5rem;
			flex: 1 1 1px;
			display: flex;
			gap: 2%;
		}

		&__column {
			width: 50%;
			@media (max-width: 1579px) {
				width: 100%;
			}
		}
	}
</style>
