/**
 * Creates a form input for each element in the array
 * and returns a form input object,
 * where the key is the id of the element.
 *
 * @param inputs array of items
 * @returns An object of objects.
 */
export function createFormInput(inputs, type) {
    let formInputs = [];
    if (type === 'obj') {
        inputs.forEach(input => {
            formInputs[input.id] = {
                name: input.id,
                type: 'checkbox',
                value: input.isEnabled
            };
        });
    }
    else {
        inputs.forEach(input => {
            formInputs.push({
                name: input.name,
                type: 'checkbox',
                value: input.isEnabled,
                label: input.name,
                isValid: true
            });
        });
    }
    return formInputs;
}
