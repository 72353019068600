import { writable } from 'svelte/store';
export const snackbarConfig = writable(null);
const baseSimpleSnackbar = {
    dismissButton: true,
    props: {
        leading: true,
    },
};
export const presentSimpleSnackbar = (type, message) => {
    snackbarConfig.set(Object.assign(Object.assign({}, baseSimpleSnackbar), { label: message, props: Object.assign(Object.assign({}, baseSimpleSnackbar.props), { class: `toast-${type}` }) }));
};
