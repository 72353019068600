<script lang="ts">
	// -------------------------------------------------- ANCHOR: IMPORTS

	import type { Order } from '../core/models/order.model';
	import { setOrderAsDiscarded, setOrderToNextStatus } from '../core/services/api/orders.service';
	import { presentConfirmationDialog } from '../core/services/internals/dialog.service';
	import { presentSimpleSnackbar } from '../core/services/internals/snackbar.service';
	import { parseCurrency } from '../core/pipes/currency';
	import { parseOptions } from 'app/core/pipes/options';
	import { parseTime } from 'app/core/pipes/time';
	import formatPhoneNumber from '../core/pipes/phoneNumber';
	import { afterUpdate } from 'svelte';

	// -------------------------------------------------- ANCHOR: PROPS

	export let order: Order;

	const upper = [
		{ text: 'Orden: ', name: 'orderFolio', class: 'order-folio-title' },
		{ text: '', name: 'contactName', class: 'order-contact-title text-right' },
		{ text: 'Tel: ', name: 'contactPhone', class: 'order-contact-phone-title' },
		{ text: 'Total: ', name: 'orderAmount', class: 'order-total-title text-right' },
	];

	const headers = [
		{ text: 'Producto', key: 'products', spanned: false },
		{ text: 'Cantidad', key: 'quantity', spanned: true },
		{ text: 'Entrega y notas', key: 'general', spanned: false },
		{ text: '', key: 'buttons', spanned: false },
	];

	const buttonLabelByStatus = {
		pending: 'Preparar',
		in_progress: 'Preparado',
		done: 'Entregar',
	};

	const statusLabel = {
		in_progress: 'En preparación',
		done: 'Preparado',
		pending: 'Pendiente',
	};

	// -------------------------------------------------- ANCHOR: STATE

	// -------------------------------------------------- ANCHOR: ACTIONS

	const discardOrder = async () => {
		const discardOrder = await presentConfirmationDialog({
			title: 'Descartar pedido',
			text: 'Se descartará la orden de forma permanente',
			confirmButtonText: 'Descartar',
			declineButtonText: 'Cancelar',
		});

		if (!discardOrder) return;
		await setOrderAsDiscarded(order.id).then((res) => {
			if (res.message.length > 0 || res.status === 'fail') return;
			presentSimpleSnackbar('warning', `Orden: ${order.orderFolio} descartada.`);
		});
	};

	const updateStatus = async () => {
		await setOrderToNextStatus(order);
	};

	setTimeout(function(){
		order.isNewOrder = false;
	}, 1000);


</script>
<div class="order-card {order.isNewOrder ? 'newOrder' : ''}">
	<div class="upper">
		{#each upper as orderSummary}
			<span class={orderSummary.class}>
					<b>{orderSummary.text}</b>
				{#if orderSummary.name === 'orderFolio'}
					#{order.orderFolio}
				{:else if orderSummary.name === 'contactName'}
					{order.contact.name} {order.contact.lastName}
				{:else if orderSummary.name === 'contactPhone'}
					{formatPhoneNumber(order.contact.phone)}
				{:else if orderSummary.name === 'orderAmount'}
					${parseCurrency(order.amount)}
				{/if}
			</span>
		{/each}
	</div>
	<div class={'card ' + order.status}>
		<div class="header">
			{#each headers as header}
				<span class={header.key}>
					{#if header.key === 'buttons'}
						<span class='circle-status {order.status}'> </span>
						<p class='{order.status}'>{statusLabel[order.status] || ''}</p>	
					{:else}
						 {header.text}
					{/if}
				</span>
			{/each}
		</div>
		<div class="body">
			{#each headers as header}
				{#if !header.spanned}
					<span class={header.key}>
						{#if header.key === 'general'}
							<p class="general-delivery-time">{parseTime(order.deliveryTime)}</p>
							{#if order.notes}
								<p class="general-notes-title">Notas generales:</p>
								<p class="general-notes">{order.notes}</p>
							{/if}
						{:else if header.key === 'buttons' && order.status !== 'delivered'}
							<button class={order.status} on:click={() => updateStatus()}>
								{buttonLabelByStatus[order.status]}
							</button>
							<button class="discard" on:click={() => discardOrder()}>Descartar</button>
						{:else if header.key === 'products'}
							{#each order.products as product}
								<span class="product">
									<span class="product-info">
										<span class="product-thumbnail">
											<img src={product.image.thumbnail} alt="product" />
										</span>
										<span class="product-summary">
											<p>{product.name}</p>
											<p>
												{ parseOptions(product.options) }
											</p>
											<p>{product.productNotes}</p>
										</span>
									</span>
									<span class="product-quantity">{product.quantity}</span>
								</span>
							{/each}
						{/if}
					</span>
				{/if}
			{/each}
		</div>
	</div>
</div>

<!--  -------------------------------------------------- ANCHOR: STYLES -->
<style lang="scss">
	@use './../../assets/styles/vars/colors' as colors;
	@use './../../assets/styles/vars/fonts/typography' as typo;
	@use 'sass:color';

	.order-card {
		margin-bottom: 2rem;
	}

	.upper {
		display: flex !important;
		justify-content: space-between;
		white-space: nowrap;
		gap: 0.3rem;
		flex-wrap: wrap;
	}

	.order-folio-title {
		color: colors.color(primary);
		font-size: typo.f-size(22);
		font-weight: bold;
	}

	.order-contact-title {
		color: colors.color(neutral, gray);
		font-size: typo.f-size(20);
	}

	.order-contact-phone-title {
		color: colors.color(neutral, gray);
		font-size: typo.f-size(21);
	}

	.order-total-title {
		color: colors.color(neutral, gray);
		font-size: typo.f-size(21);
	}

	.upper,
	.header,
	.body {
		display: grid;
		grid-template-columns: 1fr 0.5fr 1fr 0.5fr;
	}
	.card {
		margin-top: 1rem;
		border: 2px solid colors.color(order-status, pending);

		&.pending {
			border: 2px solid colors.color(order-status, base);
		}
		&.in_progress {
			border: 2px solid colors.color(order-status, in_progress);
		}
		&.delivered {
			.header,
			.body {
				grid-template-columns: 1fr 0.5fr 1fr;
			}
		}
		&.done {
			border: 2px solid colors.color(order-status, done);
		}
		.header {
			border-bottom: 1px solid lighten(colors.color(primary, base), 35%);
			color: colors.color(primary, base);
			font-weight: bold;
			justify-items: center;
			font-size: 21px;
			padding-bottom: .5rem;
			align-items: center;

			span.buttons{
				display: flex;
				align-items: center;

				.circle-status{
					width: 0.625rem;
					height: 0.625rem;
					border-radius: 50%;
					display: block;

					&.pending {
						background-color: colors.color(order-status, pending);
					}
					&.in_progress {
						background-color: colors.color(order-status, in_progress);
					}
					&.done {
						background-color: colors.color(order-status, done);
					}
				}

				p {
					margin: 0;
					margin-left: 0.3rem;
					&.pending {
						color: colors.color(order-status, pending);
					}
					&.in_progress {
						color: colors.color(order-status, in_progress);
					}
					&.done {
						color: colors.color(order-status, done);
					}
				}
			}
		}

		.body {
			span {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 1rem;
				justify-content: center;

				p {
					margin: 0;
				}

				&.products {
					align-items: flex-start;
					grid-column: 1 / 3;
					.product {
						align-items: center;
						justify-content: start;
						// align-items: flex-start;
						padding: 0;
						flex-direction: row;
						gap: 1.5%;
						width: 100%;
						margin: 0.5rem 0;
						display: grid;
						// grid-template-columns: repeat(2, 1fr);
						grid-template-columns: 65% 33%;

						.product-info {
							text-align: start;
							padding: 0;
							flex-direction: row;
							justify-content: start;
							gap: 1.5rem;

							.product-thumbnail {
								width: 3rem;
								height: 3rem;
								min-width: 3rem;
								min-height: 3rem;
								max-height: 3rem;
								max-width: 3rem;
								display: inline-flex;
								vertical-align: middle;
								justify-content: center;
								margin-right: 0.5rem;
								padding-top: 0;

								img {
									max-width: 100%;
									max-height: 100%;
									object-fit: contain;
								}
							}

							.product-summary {
								text-align: start;
								align-items: flex-start;
								word-break: break-word;
								padding: 0;

								p {
									&:first-child {
										color: colors.color(neutral, gray);
										font-weight: bold;
									}

									&:nth-child(n + 2) {
										color: colors.color(primary, base);
									}

									&:last-child {
										color: colors.color(neutral, gray);
									}
								}
							}
						}

						.product-quantity {
							color: colors.color(neutral, gray);
							font-weight: bold;
							font-size: typo.f-size(22);
							padding: 0;
						}
					}
				}

				&.general {
					word-break: break-word;

					.general-delivery-time {
						font-size: 22px;
						font-weight: bold;
						color: colors.color(primary, base);
					}

					.general-notes-title {
						color: colors.color(neutral, gray);
						font-weight: bold;
						font-size: 18px;
					}
					.general-notes {
						font-size: 14px;
					}
				}

				&.buttons {
					text-align: center;
					word-break: break-word;

					button {
						border-radius: 1rem;
						padding: 0.5rem 1rem;
						width: fit-content;
						margin: 0.5rem 0;
						cursor: pointer;
						color: colors.color(primary, base);
						background-color: colors.color(neutral, white);
						word-break: keep-all;
						border: 0;

						&.pending {
							background-color: colors.color(order-status, base);
							color: colors.color(neutral, white);
							border-bottom: 6px solid darken(colors.color(order-status, base), 10%);
							user-select: none;
						}
						&.in_progress {
							background-color: colors.color(order-status, in_progress);
							color: colors.color(neutral, white);
							border-bottom: 6px solid darken(colors.color(order-status, in_progress), 10%);
							user-select: none;
						}
						&.done {
							background-color: colors.color(order-status, done);
							color: colors.color(neutral, white);
							border-bottom: 6px solid darken(colors.color(order-status, done), 10%);
							user-select: none;
						}
					}
				}
			}
		}
	}

	.newOrder{
		animation: orderAnimationScale .5s ease-in;

		.card{
			animation: orderAnimationBackground 1s ease-in;
		}
	}


@keyframes orderAnimationScale {
	0%{
		transform: scale(0);
	}
	100%{
		transform: scale(1);
	}
}

@keyframes orderAnimationBackground {
	0%{
		background-color: #fecfa1;
	}
	100%{
		background-color: white;
	}
}



</style>
