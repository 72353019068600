<script lang="ts">
	// -------------------------------------------------- ANCHOR: IMPORTS

	import { onDestroy, onMount } from 'svelte';
	import { PendingOrdersStore } from 'app/core/services/stores/orders.store';

	import { getPendingOrders } from '../core/services/api/orders.service';
	import type { Order } from '../core/models/order.model';
	import { windowWidthSize } from 'app/store';
	import OrderCardComponent from '../components/order-card-component.svelte';
	import HeaderSectionComponent from '../components/header-section-component.svelte';

	// -------------------------------------------------- ANCHOR: LIFECYCLE

	onMount(async () => {
		audio = document.getElementById(`audio`);
		const intervalQuerySeconds = 10;
		await getOrders();

		interval = setInterval(getOrders, 1000 * intervalQuerySeconds);
	});

	onDestroy(() => {
		clearInterval(interval);
	});

	// -------------------------------------------------- ANCHOR: STATE

	let interval;
	let audio : any;
	let firstColumn: Order[] = [];
	let secondColumn: Order[] = [];
	let allCards: Order[] = [];
	let storedOrders : Order[] = [];
	let _windowWidthSize: any = {};
	let existsErrorGettingOrders = false;

	$: {
		storedOrders = JSON.parse(localStorage.getItem('TREVLY_PENDING_ORDERS_LIST'));

		for (let index = 0; index < $PendingOrdersStore.length; index++) {
			const newOrder = storedOrders?.findIndex((storedOrder:Order) => storedOrder.id === $PendingOrdersStore[index].id);
			if(newOrder === -1) {
				audio.play();
				break;
			}	
		}

		if($PendingOrdersStore.length > 0 ){
			PendingOrdersStore.setStoredPendingOrdersList($PendingOrdersStore);
		}

		allCards = $PendingOrdersStore;
		orderCards();
	}

	windowWidthSize.subscribe((data) => {
		_windowWidthSize = data;
		orderCards();
	});

	// -------------------------------------------------- ANCHOR: SERVICES

	const getOrders = async () => {
		await getPendingOrders()
			.then((res) => {
				existsErrorGettingOrders = false;
			})
			.catch((err) => {
				console.log(err);
				existsErrorGettingOrders = true;
			});
	};

	function orderCards() {
		let increaseFor = 0;
		firstColumn = [];
		secondColumn = [];

		if (_windowWidthSize.large) {
			increaseFor = 2;
		} else {
			increaseFor = 1;
		}

		for (let index = 0; index < allCards.length; index += increaseFor) {
			firstColumn = [...firstColumn, allCards[index]];
			if (increaseFor >= 2 && allCards[index + 1]) {
				secondColumn = [...secondColumn, allCards[index + 1]];
			}
		}
	}
</script>

<!--  -------------------------------------------------- ANCHOR: TEMPLATE -->
<div class="page__wrapper">
	<div class="section__wrapper">
		<HeaderSectionComponent />
		<audio id="audio" src="./assets/trevly-notification.mp3"></audio>
		{#if allCards.length <= 0}
			<p class="message-notFound">Aún no hay órdenes</p>
		{:else}
			<div class="orders__wrapper">
				{#if firstColumn.length > 0}
					<div class="orders__column">
						{#each firstColumn as order (order.id)}
							<OrderCardComponent {order} />
						{/each}
					</div>
				{/if}
				{#if _windowWidthSize.large}
					<div class="orders__column">
						{#each secondColumn as order (order.id)}
							<OrderCardComponent {order} />
						{/each}
					</div>
				{/if}
			</div>
		{/if}
	</div>
</div>

<!--  -------------------------------------------------- ANCHOR: STYLES -->
<style lang="scss">
	.orders {
		&__wrapper {
			width: 100%;
			padding-bottom: 1rem;
			padding-inline: 0.5rem;
			flex: 1 1 1px;
			display: flex;
			gap: 2%;
		}

		&__column {
			width: 50%;

			@media (max-width: 1579px) {
				width: 100%;
			}
		}
	}
</style>
