import { AuthTokenStore } from "../stores/session.store";
import { presentSimpleSnackbar } from "../internals/snackbar.service";
import { apiURL } from "./globalVars";
const baseHeaders = {
    'Content-Type': 'application/json',
};
export async function httpRequest(endpoint, config = {}) {
    const authToken = AuthTokenStore.getToken();
    const { headers: configHeaders } = config;
    const authHeader = authToken ? { 'Authorization': `Bearer ${authToken}` } : '';
    const requestHeaders = Object.assign(Object.assign(Object.assign({}, baseHeaders), configHeaders), authHeader);
    const res = await fetch(`${apiURL}/${endpoint}`, Object.assign(Object.assign({}, config), { headers: requestHeaders })).then((res) => {
        return res;
    })
        .catch((err) => {
        presentSimpleSnackbar('danger', getError(err.message || err));
        return err;
    });
    const response = await res.json();
    if (response.status !== 'success') {
        presentSimpleSnackbar('danger', getError(response.message));
    }
    return response;
}
export function getError(error) {
    const notValidOption = error.includes('_not_valid_for_selected_product_on_current_branch');
    error = notValidOption ? 'invalid_option_to_edit' : error;
    switch (error) {
        case 'no_orders_registered_for_logged_branch':
            return 'Aún no hay ordenes registradas.';
        case 'order_already_delivered':
            return 'Está orden ya fue entregada.';
        case 'order_not_found':
            return 'Está orden no existe o fue eliminada.';
        case 'order_canceled':
            return 'Está orden fue cancelada.';
        case 'order_discarded':
            return 'Está orden fue descartada.';
        case 'option_not_found':
            return 'Esta opción no existe o ya fue eliminada.';
        case 'product_not_found':
            return 'Este producto ya no existe o fue eliminado.';
        case 'branch_disabled':
            return 'La sucursal está deshabilitada, comunicate con el administrador.';
        case 'branch_code_not_found':
            return 'El código proporcionado es incorrecto.';
        case 'body/status must be equal to one of the allowed values':
            return 'Hubo un problema cambiando el estatus de la orden, intenta más tarde.';
        case 'invalid_option_to_edit':
            return 'Una de las opciones que está intentando editar no existe o fue deshabilitada para este producto.';
        case 'branch_not_found':
        case 'invalid_branch_token':
        case 'invalid_branch_token':
            return 'Hubo un error, es necesario que inicie sesión nuevamente.';
        case 'Failed to fetch':
        case 'Network request failed':
        case 'Timeout':
            return 'Hubo un error, verifica tu conexión e intenta más tarde.';
        default:
            return 'Hubo un problema con el servidor, intenta más tarde.';
    }
}
