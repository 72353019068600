export class ProductOrder {
    constructor() {
        this._id = '';
        this.options = [];
        this.name = '';
        this.shortName = '';
        this.image = { full: '', thumbnail: '' };
        this.quantity = 0;
        this.productNotes = '';
    }
    parse(obj) {
        this._id = String(obj._id || ProductOrder.clean._id);
        this.name = String(obj.name || ProductOrder.clean.name);
        this.shortName = String(obj.shortName || ProductOrder.clean.shortName);
        this.quantity = Number(obj.quantity || ProductOrder.clean.quantity);
        this.image = (obj.image) ? obj.image : ProductOrder.clean.image;
        this.options = (obj.options) ? obj.options : ProductOrder.clean.options;
        this.productNotes = String(obj.productNotes || ProductOrder.clean.productNotes);
        return this;
    }
    set(obj) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
        return this;
    }
    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = ProductOrder.clean[key];
        });
        return this;
    }
    clone() {
        return new ProductOrder().set(this);
    }
}
ProductOrder.clean = Object.freeze(new ProductOrder());
