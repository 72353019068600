<script lang="ts">
	import type { IFormInput } from "../core/models/form-input.model";
	import { createEventDispatcher } from "svelte";
	import { validate } from "../core/helpers/validations";
	
	export let formInput: IFormInput;
	
	const dispatch = createEventDispatcher();
	let touched = false;
	let errorMessage = '';
	let timeout: any;

	/**
	 * this is beacuse of error:
	 * `'type' attribute cannot be dynamic if input uses two-way binding`
	 */
	const typeAction = (node) => {
		node.type = formInput.type;
	}

	const onInput = (fromBlur: boolean = false) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			errorMessage = validate(formInput);
			formInput.isValid = errorMessage.length === 0;

			if(!fromBlur){
				dispatch('input-change', formInput)
			}
		}, 300);
	}

	const onBlur = () => {
		touched = true;
		onInput(true);
	};

	const keyup = (e: KeyboardEvent) => {
		if (e.key === 'Enter') { // emit
			dispatch('keyup-enter', formInput)
		}
	};

</script>


<div class="input-container {formInput?.class}">
	
	{#if formInput.label}
		<label for={formInput.name}>
			{formInput.label}
		</label>
	{/if}

	<div>

		<input
			bind:value={formInput.value}
			use:typeAction
			name={formInput.name}
			placeholder={formInput.placeholder}
			class:invalid={touched && errorMessage}
			on:keyup={keyup}
			on:input={() => onInput()}
			on:blur={onBlur}
		/>

		{#if touched && errorMessage}
			<p class="error-message">{errorMessage}</p>
		{/if}
		
	</div>

</div>

<style lang="scss">
	@use "./../../assets/styles/vars/colors" as *;
	@import "./../../assets/styles/forms";

	div.search{
		width: 60%;
		margin: 0 auto;

		input{
			background-color: color(input, p-background);
			border-color: color(input, primary);
			color: color(input, p-text);

			&::placeholder{
				color: color(input, primary);
			}
		}
	}
	
</style>