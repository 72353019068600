import { writable } from 'svelte/store';
export const selectedModule = writable({
    text: '',
    name: '',
    component: null,
});
export const loading = writable({
    products: {
        getProducts: false,
        updateProduct: false,
        updateOptions: false,
    },
    orders: {
        getDeliveredOrders: false,
    }
});
export const formData = writable();
export const windowWidthSize = writable({
    medium: false,
    large: false,
    xLarge: false
});
