<script lang="ts">

	export let classSpinner: string = '';

</script>

<div class="spinner-container {classSpinner}">
    <span class="spinner"></span>
</div>

<style lang="scss">
	@import './../../assets/styles/vars/colors';

    .spinner-container{
        width: 100%;
        margin-top: 25%;
        display: grid;
        place-content: center;

        &.formSpinner{
            margin: 0;

            .spinner{
                width: 1.5rem;
                height: 1.5rem;

                &:before{
                    border: 2px solid color(neutral, white);
                }
            }
        }

        .spinner{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            position: relative;
            animation: rotate 1s linear infinite;

            &:before{
                content: "";
                box-sizing: border-box;
                position: absolute;
                inset: 0px;
                border-radius: 50%;
                border: 5px solid color(primary, base);
                animation: prixClipFix 2s linear infinite;
            }
        }
    }

    .spinner-container.form{
        margin: 0;

        .spinner{
            width: 2rem;
            height: 2rem;
        }
    }

    @keyframes rotate {
        100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
</style>
