const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/;
/**
 * Returns a string with an error message if does not pass validations
 */
export function validate(formInput) {
    // console.log(`formInput`, formInput);
    let errorMessage = '';
    /**
     * REQUIRED
     */
    if (!formInput.optional &&
        ((formInput.type === 'text' ||
            formInput.type === 'answer' ||
            formInput.type === 'date' ||
            formInput.type === 'email' ||
            formInput.type === 'file' ||
            formInput.type === 'password' ||
            formInput.type === 'number' ||
            formInput.type === 'select' ||
            formInput.type === 'textarea') &&
            String(formInput.value || '').trim().length === 0)) {
        errorMessage = 'Campo requerido';
    }
    /**
     * EMAIL
     */
    else if (formInput.type === 'email' &&
        !emailPattern.test(String(formInput.value).trim())) {
        errorMessage = 'Añadir correo electrónico válido';
    }
    /**
     * MAX
     */
    else if ('max' in formInput &&
        formInput.type === 'number' &&
        Number(formInput.value) > formInput.max) {
        errorMessage = `Número máximo permitido: ${formInput.max}`;
    }
    /**
     * MIN
     */
    else if ('min' in formInput &&
        formInput.type === 'number' &&
        Number(formInput.value) < formInput.min) {
        errorMessage = `Número mínimo permitido: ${formInput.min}`;
    }
    /**
     * MAXLENGTH
     */
    else if ('maxLength' in formInput &&
        String(formInput.value).trim().length > formInput.maxLength) {
        errorMessage = `Número máximo de caracteres permitido: ${formInput.maxLength}`;
    }
    /**
     * MINLENGTH
     */
    else if ('minLength' in formInput &&
        String(formInput.value).trim().length < formInput.minLength) {
        errorMessage = `Número mínimo de caracteres permitido: ${formInput.minLength}`;
    }
    // console.log(`errorMessage`, errorMessage);
    return errorMessage;
}
