<script lang="ts">
	// -------------------------------------------------- ANCHOR: IMPORTS

	import { onDestroy, onMount } from 'svelte';
	import { createFormInput } from '../core/helpers/form-input';
	import { formData, loading } from 'app/store';
	import { getProducts, updateProduct } from 'app/core/services/api/products.service';
	import { dialogs, ModalOptions } from 'svelte-dialogs';
	import { presentSimpleSnackbar } from 'app/core/services/internals/snackbar.service';
	import TableProductsComponents from '../components/table-products-components.svelte';
	import FormModalComponent from './../components/form-modal/form-modal-component.svelte';
	import HeaderSectionComponent from '../components/header-section-component.svelte';
	import SpinnerComponent from '../components/spinner-component.svelte';
	import type { Product } from '../core/models/product.model';
	import type { FormInput, IFormInput } from 'app/core/models/form-input.model';
	import type { IModalProps } from 'app/core/models/index.interfaces';

	// -------------------------------------------------- ANCHOR: VARS
	
	let products: Product[];
	let formProducts: any;
	let searchForm: IFormInput = {
		name: 'search',
		placeholder: 'Buscar productos',
		type: 'text',
		class: 'search',
		optional: true,
	};
	let _loading: any;
	let _productEdit : Product;

	// -------------------------------------------------- ANCHOR: SUBS
	
	loading.subscribe((data) => {
		_loading = data;
	});

	formData.subscribe((data : any) => {
		if(data && _productEdit){
			const index = products.findIndex(element => element.id === _productEdit.id);
			products[index] = {...data};
		}
	});

	// -------------------------------------------------- ANCHOR: LIFECYCLES
	
	onMount(() => {
		loadProducts();
	});

	onDestroy(() =>{
		formData.set(null);
	});

	// -------------------------------------------------- ANCHOR: API

	const loadProducts = async (searchText: string = null) => {
		products = [...(await getProducts(searchText))];
		formProducts = createFormInput(products, 'obj');
	};

	const update = (data: any) => {
		updateProduct(data.detail);
	};

	const search = async (data: any) => {
		loadProducts(data.detail);
	};

	// -------------------------------------------------- ANCHOR: MODAL

	const productModal: IModalProps = {
		title: '',
		type: 'secondary',
		saveButton: 'Guardar',
		formInputs: [],
		category: 'modal',
	};

	const modalOptions: ModalOptions = {
		content: FormModalComponent,
		closeButton: true,
		closeOnBg: true,
		closeOnEsc: true,
		overlayClass: 'modal-overlay',
		dialogClass: 'modal-dialog',
		props: productModal,
	};

	const openModal = async (data: any) => {
		_productEdit = data.detail as Product;
		if(_productEdit.options.length > 0){
			formData.set(_productEdit);
			productModal.title = _productEdit.name;
			const formOptions = createFormInput(_productEdit.options, 'array');
			productModal.formInputs = formOptions;
			await dialogs.modal(modalOptions, productModal).then((a) => console.log(a));
		} else {
			presentSimpleSnackbar('warning', 'El producto no cuenta con opciones para editar');
		}
	}

</script>

<div class="page__wrapper">
	<div class="section__wrapper">
		<HeaderSectionComponent {searchForm} on:search-change={(data) => search(data)} />
		<div class="products__wrapper">
			{#if _loading.products.getProducts}
				<SpinnerComponent />
			{:else if products?.length > 0}
				<TableProductsComponents 
					{formProducts} 
					rows={products} 
					on:update-product={(data) => update(data)}
					on:row-selected={(data)=> openModal(data)}
				/>
			{:else if searchForm?.value}
				<p class="message-notFound">No se encontraron resultados</p>
			{:else}
				<p class="message-notFound">No hay productos registrados</p>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	.products__wrapper {
		width: 100%;
		padding-bottom: 1rem;
		padding-inline: 0.5rem;
	}
</style>
