import { writable, get } from 'svelte/store';
// Retrieve value from localstorage
const storedToken = localStorage.getItem('TREVLY_ORDERS_TOKEN');
const storedBranch = JSON.parse(localStorage.getItem('TREVLY_ORDERS_BRANCH'));
// Create store values with default localstorage values if exists
const authToken$ = writable(storedToken || '');
const branch$ = writable(storedBranch || null);
// Set to localstorage on value change
authToken$.subscribe((newToken) => localStorage.setItem('TREVLY_ORDERS_TOKEN', newToken));
branch$.subscribe((newBranchData) => localStorage.setItem('TREVLY_ORDERS_BRANCH', JSON.stringify(newBranchData)));
const getAuthToken = () => get(authToken$);
const getBranchData = () => get(branch$);
export const AuthTokenStore = {
    subscribe: authToken$.subscribe,
    setToken: authToken$.set,
    getToken: getAuthToken
};
export const BranchDataTokenStore = {
    subscribe: branch$.subscribe,
    setBranch: branch$.set,
    getBranch: getBranchData
};
