<script lang="ts">
	import ProductsPageComponent from './../pages/products-page-component.svelte';
	import PendingPageComponent from './../pages/pending-page-component.svelte';
	import DeliveriedPageComponent from '../pages/delivered-page-component.svelte';
	import { selectedModule } from './../store';
	import type { IModuleComponent } from 'app/core/models/index.interfaces';
	import { BranchDataTokenStore } from 'app/core/services/stores/session.store';
	import { createEventDispatcher } from 'svelte';

	const modules: IModuleComponent[] = [
		{
			text: 'Pendientes',
			name: 'pending',
			component: PendingPageComponent,
		},
		{
			text: 'Entregadas',
			name: 'deliveries',
			component: DeliveriedPageComponent,
		},
		{
			text: 'Productos',
			name: 'products',
			component: ProductsPageComponent,
		},
	];

	let branchNumber: string;
	let activeModule: IModuleComponent;
	const dispatch = createEventDispatcher();

	$: branchNumber = $BranchDataTokenStore?.branchNumber ?? '';
	$: activeModule = $selectedModule;

	// selectedModule.subscribe((selectedModule) => (activeModule = selectedModule));

	// BranchDataTokenStore.subscribe((data) => {
	// 	branchNumber = data.branchNumber;
	// });

	function handleClickMenu(module: IModuleComponent) {
		selectedModule.set(module);
	}

	function changeBranch() {
		dispatch('change-branch');
	}
</script>

<header class="topbar">
	<div class="logo">
		<img src="./assets/images/logo.svg" alt="logo trevly" />
	</div>
	<div class="info__wrapper">
		{#each modules as module}
			<button class:active={module.name === activeModule.name} on:click={() => handleClickMenu(module)}>
				<img src="./assets/images/icons/icon-{module.name}.svg" alt="imagen modulo {module.text}" />
				<label for="">
					{module.text}
				</label>
			</button>
		{/each}
		<span on:click={() => changeBranch()}>
			Sucursal {branchNumber}
		</span>
	</div>
</header>

<style lang="scss">
	@import './../../assets/styles/vars/colors';
	.topbar {
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: color(primary, base);
		color: color(neutral, white);
		border-bottom: 0.4rem solid color(primary, shadow);

		.logo {
			max-width: 12rem;
			img {
				height: 100%;
				width: 100%;
			}
		}

		.info__wrapper {
			display: flex;
			button {
				background: transparent;
				border: 0;
				user-select: none;
				cursor: pointer;
				padding-inline: 2rem;
				width: 6.8rem;
				height: 3.5rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: color(neutral, white);

				&:nth-child(2) {
					border-inline: 2px solid color(neutral, white);
				}

				&:hover {
					background: #cf4173;
				}

				&.active > * {
					filter: opacity(1);
				}

				> * {
					filter: opacity(0.5);
				}

				img {
					width: 100%;
					height: 80%;
					margin-bottom: 0.5rem;
				}
			}

			span {
				margin-left: 1rem;
				cursor: pointer;
			}
		}
	}
</style>
