export class Product {
    constructor() {
        this.id = '';
        this.totalOptions = 0;
        this.name = '';
        this.shortName = '';
        this.image = { full: '', thumbnail: '' };
        this.isEnabled = true;
        this.enabledOptions = 0;
        this.disabledOptions = 0;
        this.options = [];
    }
    parse(obj) {
        this.id = String(obj._id || Product.clean.id);
        this.name = String(obj.name || Product.clean.name);
        this.shortName = String(obj.shortName || Product.clean.shortName);
        this.totalOptions = Number(obj.totalOptions || Product.clean.totalOptions);
        this.disabledOptions = Number(obj.disabledOptions || Product.clean.disabledOptions);
        this.enabledOptions = Number(obj.enabledOptions || Product.clean.enabledOptions);
        this.isEnabled = obj.isEnabled;
        this.image = (obj.image) ? obj.image : Product.clean.image;
        this.options = (obj.options || Product.clean.options).map(option => option);
        return this;
    }
    set(obj) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
        return this;
    }
    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Product.clean[key];
        });
        return this;
    }
    clone() {
        return new Product().set(this);
    }
}
Product.clean = Object.freeze(new Product());
