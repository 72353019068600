import { ProductOrder } from "./product-order.model";
export class Order {
    constructor() {
        this.id = '';
        this.amount = 0;
        this.deliveryTime = '';
        this.notes = '';
        this.orderFolio = '';
        this.products = [];
        this.quantity = [];
        this.status = 'pending';
        this.isNewOrder = false;
    }
    parse(obj) {
        this.id = String(obj._id || Order.clean.id);
        this.amount = Number(obj.amount || Order.clean.amount);
        this.contact = (obj.contact) ? obj.contact : Order.clean.contact;
        this.deliveryTime = String(obj.deliveryTime || Order.clean.deliveryTime);
        this.notes = String(obj.notes || Order.clean.notes);
        this.orderFolio = String(obj.orderFolio || Order.clean.orderFolio);
        this.products = (obj.products || []).map((product) => new ProductOrder().parse(product));
        this.status = (obj.status || Order.clean.status);
        this.updatedAt = (obj.updatedAt) ? new Date(obj.updatedAt) : Order.clean.updatedAt;
        this.isNewOrder = Order.clean.isNewOrder;
        if (obj.products.length > 0) {
            obj.products.forEach(element => {
                this.quantity.push(element.quantity);
            });
        }
        return this;
    }
    set(obj) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
        return this;
    }
    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Order.clean[key];
        });
        return this;
    }
    clone() {
        return new Order().set(this);
    }
}
Order.clean = Object.freeze(new Order());
