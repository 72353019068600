export function parseTime(time) {
    let parsedTime = '';
    let timeParts = time.split(':');
    let hours = Number(timeParts[0]);
    let minutes = timeParts[1];
    if (hours <= 12) {
        parsedTime = `${(hours === 0) ? 12 : hours}:${minutes} ${(hours === 12) ? 'PM' : 'AM'}`;
    }
    else {
        parsedTime = `${hours - 12}:${minutes} PM`;
    }
    return parsedTime;
}
;
