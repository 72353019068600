export class Branch {
    constructor() {
        this._id = '';
        this.branchNumber = '';
        this.name = '';
        this.mall = '';
    }
    parse(obj) {
        this._id = String(obj._id || Branch.clean._id);
        this.branchNumber = String(obj.branchNumber || Branch.clean.branchNumber);
        this.name = String(obj.name || Branch.clean.name);
        this.mall = String(obj.mall || Branch.clean.name);
        return this;
    }
    set(obj) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
        return this;
    }
    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Branch.clean[key];
        });
        return this;
    }
    clone() {
        return new Branch().set(this);
    }
}
Branch.clean = Object.freeze(new Branch());
