<script lang="ts">
	// -------------------------------------------------- ANCHOR: IMPORTS

	import { AuthTokenStore, BranchDataTokenStore } from './app/core/services/stores/session.store';
	import { onMount } from 'svelte';
	import { snackbarConfig } from 'app/core/services/internals/snackbar.service';
	import { loading, selectedModule, windowWidthSize } from './app/store';
	import { dialogs, ModalOptions } from 'svelte-dialogs';
	import Kitchen from '@smui/snackbar/kitchen';
	import TopbarComponent from './app/components/topbar-component.svelte';
	import FormModalComponent from './app/components/form-modal/form-modal-component.svelte';
	import PendingPageComponent from './app/pages/pending-page-component.svelte';
	import type { Config, KitchenComponentDev } from '@smui/snackbar/kitchen';
	import type { IModalProps, IModuleComponent } from './app/core/models/index.interfaces';

	if (window) {
		window.console.log = () => {};
		window.console.warn = () => {};
		window.console.error = () => {};
	}

	// -------------------------------------------------- ANCHOR: LIFECYCLE

	onMount(() => {
		getWindowSize();
		selectedModule.set({
			text: 'Pendientes',
			name: 'pending',
			component: PendingPageComponent,
		});
	});

	// -------------------------------------------------- ANCHOR: STATE

	let module: IModuleComponent;
	let isUserLogged = false;
	let notificationConfig: Config;
	let notification: KitchenComponentDev;
	let _windowWidthSize: any = {};

	$: module = $selectedModule;
	$: {
		isUserLogged = Boolean($AuthTokenStore);
		if (!isUserLogged || !$BranchDataTokenStore) openLoginModal();
	}
	$: {
		notificationConfig = $snackbarConfig;
		notificationConfig && notification.push({ ...notificationConfig });
	}

	$: _windowWidthSize = $windowWidthSize;

	const loginData: IModalProps = {
		title: '¡Bienvenido!',
		type: 'secondary',
		message: 'Ingresa el código de sucursal:',
		saveButton: 'Entrar',
		formInputs: [
			{
				type: 'password',
				name: 'branchCode',
			},
		],
		category: 'login',
	};

	const modalOptions: ModalOptions = {
		content: FormModalComponent,
		closeButton: true,
		closeOnBg: false,
		closeOnEsc: false,
		overlayClass: 'login-overlay',
		dialogClass: 'login-dialog',
		props: loginData,
	};

	const openLoginModal = async () => {
		await dialogs.modal(modalOptions, loginData).then((a) => console.log(a));
	};

	const getWindowSize = () => {
		_windowWidthSize.medium = window.innerWidth >= 640;
		_windowWidthSize.large = window.innerWidth >= 1580;
		_windowWidthSize.xLarge = window.innerWidth >= 1950;
		windowWidthSize.set(_windowWidthSize);
	};

	window.addEventListener('resize', () => {
		getWindowSize();
	});

	const changeBranch = async () => {
		loginData.title = 'Cambiar sucursal';
		loginData.saveButton = 'Aceptar';
		loginData.category = 'login-secondary';
		loginData.formInputs[0].value = '';

		modalOptions.overlayClass = 'modal-overlay';
		modalOptions.dialogClass = 'modal-dialog';
		modalOptions.closeButton = false;
		modalOptions.closeOnBg = true;
		modalOptions.closeOnEsc = true;

		await dialogs.modal(modalOptions, loginData).then((a) => console.log(a));
	};
</script>

<!-- -------------------------------------------------- ANCHOR: TEMPLATE -->

{#if isUserLogged}
	<main>
		<TopbarComponent on:change-branch={() => changeBranch()} />
		<svelte:component this={module.component} />
	</main>
{/if}
<Kitchen bind:this={notification} dismiss$class="material-icons" />

<!-- -------------------------------------------------- ANCHOR: STYLES -->
<style lang="scss" type="global">
	@import './assets/styles/global.scss';
	main {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
</style>
