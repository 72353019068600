export function parseCurrency(currency) {
    let parsedCurrency = '';
    let currencyParts = currency.toString().split('.');
    let currencyWhole = currencyParts[0];
    let currencyDecimals = (currencyParts[1]) ? currencyParts[1] : '00';
    if (currencyWhole.length < 4) {
        parsedCurrency = currencyWhole.toString();
    }
    else {
        let index = 0;
        do {
            if (index === 0) {
                let firstPosition = (currencyWhole.length % 3 === 0) ? 3 : currencyWhole.length % 3;
                parsedCurrency = `${currencyWhole.slice(index, firstPosition)}`;
                index = firstPosition;
            }
            else {
                parsedCurrency = parsedCurrency + `,${currencyWhole.slice(index, index + 3)}`;
                index = index + 3;
            }
        } while (index < currencyWhole.length);
    }
    parsedCurrency = parsedCurrency + `.${currencyDecimals}`;
    return parsedCurrency;
}
;
