import parseAuthError from './auth.errors';
import { AuthTokenStore, BranchDataTokenStore } from "../stores/session.store";
import { httpRequest } from "../api/http.service";
import { Branch } from '../../models/branch-data.model';
import { PendingOrdersStore } from '../stores/orders.store';
import { selectedModule } from 'app/store';
import PendingPageComponent from "../../../pages/pending-page-component.svelte";
// -------------------------------------------------- ANCHOR: SERVICES
export async function authenticate(branchCode) {
    const { data, status, message } = await httpRequest(`in`, {
        method: 'POST',
        body: JSON.stringify({ branchCode }),
    });
    if (status === 'fail' && message) {
        throw new Error(parseAuthError(message));
    }
    if (status !== 'success') {
        throw new Error('Invalid response status.');
    }
    if (!data.token || !data.branch) {
        throw new Error('Missing data in response.');
    }
    localStorage.setItem('TREVLY_PENDING_ORDERS_QUERY_TIMESTAMP', '');
    PendingOrdersStore.clear();
    selectedModule.set({
        text: 'Pendientes',
        name: 'pending',
        component: PendingPageComponent
    });
    AuthTokenStore.setToken(data.token);
    BranchDataTokenStore.setBranch(new Branch().parse(data.branch));
}
