<script lang="ts">
	// -------------------------------------------------- ANCHOR: IMPORTS

	import { onMount } from 'svelte';

	import type { IFormInput } from '../../core/models/form-input.model';

	import { getClose } from 'svelte-dialogs';
	import { authenticate } from 'app/core/services/auth/auth.service';
	import { presentSimpleSnackbar } from 'app/core/services/internals/snackbar.service';
	import { updateOptions } from 'app/core/services/api/products.service';
	import { loading } from 'app/store';
	import InputText from '../input-text.svelte';
	import InputToggle from '../input-toggle.svelte';
	import SpinnerComponent from '../spinner-component.svelte';

	// -------------------------------------------------- ANCHOR: PROPS

	export let title: string;
	export let type: string;
	export let message: string;
	export let saveButton: string;
	export let category: string;
	export let formInputs: IFormInput[];

	// -------------------------------------------------- ANCHOR: STATE

	let isFormValid = false;

	// -------------------------------------------------- ANCHOR: LIFECYCLE

	onMount(() => {
		console.groupCollapsed(`Modal on mount`);
		console.log(`title      `, title);
		console.log(`type       `, type);
		console.log(`message    `, message);
		console.log(`saveButton `, saveButton);
		console.log(`formInputs `, formInputs);
		console.groupEnd();
	});

	// -------------------------------------------------- ANCHOR: FORM EVENTS

	const handleInputChange = (e: CustomEvent) => {
		isFormValid = !formInputs?.some((formInput: IFormInput) => !formInput.isValid);
		console.log(formInputs);
	};

	const onSave = () => {
		console.log(`onSave sectionFormData`);
		console.log(`isFormValid`, isFormValid);
		console.log(`formInputs`, formInputs);

		if (!isFormValid) return;

		if (category === 'login' || category === 'login-secondary') {
			authenticate(formInputs[0].value).then((value) => {
				console.log(value);
				console.log('SUCCESS');
				presentSimpleSnackbar('success', 'Bienvenido!');
				close(this);
			});
		} else {
			updateOptions(formInputs)
				.then((res) => {
					if(res) { close(this); }
				});
		}
	};

	// -------------------------------------------------- ANCHOR: MODAL EVENTS

	const close = getClose();

	// -------------------------------------------------- ANCHOR: SUBS

	let _loading;
	loading.subscribe((data) => {
		_loading = data;
	});
</script>

<!-- -------------------------------------------------- ANCHOR: TEMPLATE -->

<section class="modal-container modal-{category}">
	<header>
		<h3>{@html title || ''}</h3>
	</header>

	{#if message}
		<p class="message">{message}</p>
	{/if}

	<div class="row">
		{#if formInputs?.length}
			{#each formInputs as formInput}
				<div class="col-{formInput.columns ? formInput.columns : '12'}">
					{#if formInput.type === 'password'}
						<InputText {formInput} on:input-change={handleInputChange} on:keyup-enter={onSave} />
					{:else if formInput.type === 'checkbox'}
						<InputToggle {formInput} on:toggle-change={(event) => handleInputChange(event)} />
					{/if}
				</div>
			{/each}
		{/if}
	</div>

	<button
		class="btn-rounded btn-shadow save-button btn-{category}"
		disabled={!isFormValid || _loading.products.updateOptions}
		on:click={onSave}
		type="button"
	>
		{saveButton || 'Continuar'}
		{#if _loading.products.updateOptions}
			<SpinnerComponent classSpinner="form" />
		{/if}
	</button>
</section>

<!-- -------------------------------------------------- ANCHOR: STYLES -->
<style lang="scss">
	@use './../../../assets/styles/vars/colors' as *;
	@import './../../../assets/styles/components/buttons';

	.modal-container {
		padding: 2rem;
		margin: 0.5rem;
		width: 60vw;
		max-width: 45rem;
		min-width: 20rem;
		min-height: 20rem;
		height: 25rem;
		max-height: 60rem;
		overflow: auto;
		font-family: 'Assistant', sans-serif;

		h3,
		p.message {
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
		}

		p.message {
			font-size: 1.5rem;
		}

		.save-button {
			display: block;
			margin: 3rem auto;
		}
	}

	.modal-container.modal-modal {
		color: color(neutral, gray);
		font-weight: bold;
		
		h3{
			color: color(primary, base) !important;
		}
		.row {
			display: grid;
			grid-template-columns: repeat(2, 49%);
			gap: 2%;
		}

		button {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			gap: 2%;
			justify-content: space-between;
		}
	}
</style>
