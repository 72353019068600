import { get, writable } from 'svelte/store';
// Retrieve value from localstorag
const storedTotalDailyOrdersCount = JSON.parse(localStorage.getItem('TREVLY_TOTAL_DAILY_ORDERS_COUNT'));
// Create store values with default localstorage values if exists
const pendingOrdersList$ = writable([]);
const totalDailyOrdersCount$ = writable(storedTotalDailyOrdersCount || 0);
// Set to localstorage on value change
totalDailyOrdersCount$.subscribe((total) => localStorage.setItem('TREVLY_TOTAL_DAILY_ORDERS_COUNT', (total === null || total === void 0 ? void 0 : total.toString()) || '0'));
const clear = () => {
    pendingOrdersList$.set([]);
    totalDailyOrdersCount$.set(0);
};
// Store updating functions
const appendPendingOrders = (...newPendingOrders) => {
    pendingOrdersList$.update((prevState) => {
        if (prevState.length > 0) {
            newPendingOrders === null || newPendingOrders === void 0 ? void 0 : newPendingOrders.forEach(order => {
                order.isNewOrder = true;
            });
        }
        prevState = [...prevState, ...newPendingOrders];
        return prevState.sort((a, b) => a.deliveryTime.localeCompare(b.deliveryTime));
    });
};
const discardPendingOrder = (pendingOrderIdToDiscard) => {
    pendingOrdersList$.update((prevState) => {
        return prevState.filter((order) => order.id !== pendingOrderIdToDiscard);
    });
};
const updatePendingOrderStatus = (pendingOrderToUpdate, newStatus) => {
    pendingOrdersList$.update((prevState) => {
        const orderToUpdate = prevState.find((order) => pendingOrderToUpdate.id === order.id);
        orderToUpdate.status = newStatus;
        return prevState;
    });
};
const updateTotalDailyOrdersCount = (total) => totalDailyOrdersCount$.set(total);
const getCurrentTotalDailyOrdersCount = () => JSON.parse(localStorage.getItem('TREVLY_TOTAL_DAILY_ORDERS_COUNT'));
const getPendingOrderListCount = () => get(pendingOrdersList$).length;
const setStoredPendingOrdersList = (orders) => {
    localStorage.setItem('TREVLY_PENDING_ORDERS_LIST', JSON.stringify(orders) || '[]');
};
// Exposed or 'public' store
export const PendingOrdersStore = {
    subscribe: pendingOrdersList$.subscribe,
    append: appendPendingOrders,
    discard: discardPendingOrder,
    deliver: discardPendingOrder,
    updateState: updatePendingOrderStatus,
    updateTotalOrders: updateTotalDailyOrdersCount,
    getCurrentTotalOrders: getCurrentTotalDailyOrdersCount,
    getCurrentPendingOrderListTotal: getPendingOrderListCount,
    clear: clear,
    setStoredPendingOrdersList: setStoredPendingOrdersList
};
