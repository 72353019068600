<script lang="ts">

    import InputToggle from "./input-toggle.svelte"
	import { createEventDispatcher } from 'svelte';
    import type { Product } from "../core/models/product.model";
import { loading } from "app/store";
import SpinnerComponent from "./spinner-component.svelte";

    export let rows : Product[];    
    export let formProducts : any;

    const dispatch = createEventDispatcher();
    const headers = [
        { text: '',               name: 'name'           },
        { text: 'Opciones',       name: 'totalOptions'   },
        { text: 'Habilitadas',    name: 'enabledOptions' },
        { text: 'Deshabilitadas', name: 'disabledOptions'},
        { text: '',               name: 'toggle'         }
    ]

    let numberClicks = 0;
    let productUpdate;
    let _loading;

    const onSave = (event: any) => {
        productUpdate = event.detail;
        dispatch('update-product', event.detail);
	};


    const selectRow = (row:any, event: any) => {
        if(event.path.length < 16 ){
            dispatch('row-selected', row);
        }
	};

    loading.subscribe((data) => {
        _loading = data;
    })
</script>

<div class="table">
    <div class="table__header">
        {#each headers as header}
            <span class="table__column table__title">
                {header.text}
            </span>
        {/each}
    </div>
    <div class="table__body">
        {#each rows as row}
            <div 
                class="table__row card" 
                on:click={(event) => selectRow(row, event)}
            >
                {#each headers as header}
                    <div class="table__column table__data">                                                                                                                                 
                        {#if header.name === "name"}
                            <span class="image">
                                <img src="{row.image.thumbnail}" alt="imagen del producto">
                            </span>
                            <p>{row[header.name]}</p>
                        {:else if header.name === "toggle"}
                            {#if _loading.products.updateProduct && row.id === productUpdate.name}
                                <SpinnerComponent/>     
                            {:else}
                                <InputToggle formInput={formProducts[`${row.id}`]} on:toggle-change={event => onSave(event)} />
                            {/if}
                        {:else}
                            <p>{row[header.name]}</p>
                        {/if}
                    </div>
                {/each}
            </div>
        {/each}
    </div>
</div>

<style lang="scss">
	@import './../../assets/styles/vars/colors';
	@import './../../assets/styles/vars/fonts/font-size';
	.grid {
		min-height: 3.2rem;
		max-height: fit-content;
		display: grid;
		align-items: center;
		justify-items: center;
		grid-template-columns: 30% 19% 19% 19% 9%;
		gap: 1%;
	}

	.card {
		border-radius: 1rem;
	}

	.table {
		&__header {
			@extend .grid;
		}

		&__row {
			@extend .grid;

            &:hover {
                background-color: color(card, hover);
            }

			.table__column {
				color: color(neutral, text);
				font-weight: bold;
				font-size: $f-size-21;

            .image{
                width: 3rem;
                height: 3rem;
                display: inline-flex;
                vertical-align: middle;
                justify-content: center;
                margin-right: .5rem;

                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }

            p{
                min-width: 2rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .table__column:first-of-type{
            justify-self: self-start;
            display: flex;
            align-items: center;
            border-image: linear-gradient(to bottom, #ffffff, #e0457b, #ffffff) 1;
            border-right: 2px solid;
            color: color(primary, base);
            font-size: $f-size-22;

            p {
                text-align: start;
            }
        }
    }

    &__column{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
}

</style>